<template>
  <div id="app">
    <Landing />
  </div>
</template>

<script>
import Landing from "./components/Landing.vue";


export default {
  name: "App",
  components: {
    Landing,    
  },
};
</script>

<style>
  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', Avenir, Helvetica, Arial, sans-serif;
}
 body{
     background-color:black;
  }
/* * { border: 1px solid red; } */
#app {
  overflow: hidden;
  color: #991D1D;
}
</style>
