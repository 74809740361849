import Vue from "vue";
import App from "./App.vue";

import VueAwesomeSwiper from 'vue-awesome-swiper'
// import swiper, { Navigation, Pagination, Autoplay } from 'swiper'

// import style (<= Swiper 5.x)
import 'swiper/css/swiper.css'
// import carrousel from './components/carrousel.vue';
// Vue.component('carrousel', carrousel);

Vue.use(VueAwesomeSwiper, /* { default options with global component } */)
// swiper.use([Navigation, Pagination, Autoplay])
//上面這行官方文件也沒寫到。

Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
}).$mount("#app");
