<template>
    <div class="wrapper">
        <!-- About -->
        <div class="about">
            <div class="container">
                <div class="about__block">
                    <img class="about__logo" src="../assets/logo.svg" alt="logo">
                    <div class="about__textBlock">
                        <h1 class="about__title">Guai <br/>(怪)</h1>
                        <p class="about__text">Born in Shan Hai Jing, the metaverse of Guai(s).</p>
                    </div>
                </div>
            </div>
        </div>
 
        <!-- Footer -->
        <footer>
            <div class="container">
                <div class="footer__row">
                    <!-- <img class="footer__logo" src="../assets/footer_logo.svg" alt="footer_logo"> -->
                    <div class="footer__social">
                        <a href="https://twitter.com/Guaiart" target="_blank">
                            <img src="../assets/social_twitter.svg" alt="twitter">
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>

<script>

// import GallerySwiper from './GallerySwiper.vue';
export default {
  name: "Landing",
  // components:{
  //   GallerySwiper,
  // },

};
</script>

<style lang="scss">
    h3{
        font-size: 58px;
        font-weight: 100;
    }
    p{
        font-size: 15px;
        line-height: 30px;
    }
    .wrapper{
        // background-image: url(../assets/bg.png);
        // background-size: cover;
        // background-repeat: no-repeat;
    }
    .container{
        max-width: 1500px;
        margin: auto;
        position: relative;
    }
    .about{
        align-items: center;
        color: #FFFFFF;
        text-align: center;
        padding-bottom: 200px;
        &__block{
            padding: 10% 0;
            display: flex;
            justify-content: space-between;
            margin: 0 5% 0 5%;
        }
        &__logo{
            max-width: 480px;
        }
        &__textBlock{
            margin-top: 100px;
        }

        &__title{
            line-height: 72px;
            font-size: 58px;
            font-weight: 100;
        }
        &__text{
            text-align: center;
            padding: 50px 0;
            font-size: 20px;
            line-height: 30px;
        }
        &__btn{
            padding: 16px 78px;
            background-color: #000000;
            border-radius: 5px;
            color: #FFFFFF;
            cursor: pointer;
            border: none;
            font-size: 17px;
            text-decoration: none;
            display: block;
            width: 30%;
            margin: auto;
            padding: 16px 0px;

            &:hover{
                background-color: #FFFFFF;
                // color: #000000;
                color: #991D1D;
            }
        }
    }

 
    .footer{
        &__row{
            text-align: center;
        }
        &__logo{
            padding: 20px 0 ;
        }
        &__social{
            padding: 10px 0 100px;
            a{
                cursor: pointer;
            }
            img{
                max-width: 40px;
                margin: 0 2px;
            }
        }
    }

    @media screen and (max-width: 1400px) {

        h3{
            font-size: 40px;
        }
        .about{
            padding-bottom: 0;

            &__block{
                flex-direction: column;
            }
            &__logo{
                margin: auto;
                max-width: 400px;
            }
            &__textBlock{
                padding: 150px 0 0 0;
                text-align: center;
            }
        }

        .footer{
            &__logo{
                max-width: 150px;
            }
            &__social{
                img{
                    max-width: 25px;
                    margin: 0 5px;
                }
            }
        }

    }

    @media screen and (max-width: 1024px){
        .about{
            &__logo{
                max-width: 400px;
            }
        }
    }

    @media screen and (max-width: 768px){
        h3{
            font-size: 30px;
        }
        .about{
            &__textBlock{
                padding: 25px 0 0 0;
            }
            &__title{
                font-size: 45px;
            }
            &__logo{
                max-width: 250px;
            }
        }

        .footer{
            &__logo{
                max-width: 100px;
            }
            &__social{
                img{
                    max-width: 20px;
                    margin: 0 5px;
                }
            }
        }
    }

    @media screen and (max-width: 500px){
        .about{
            &__logo{
                max-width: 150px;
            }
            &__btn{
                border: 1px solid #ffffff;
            }
        }
    }

</style>
